/* + + + + + + + + + + + + + + + + + + + + + */
/* TEMPLATE STYLES */
/* + + + + + + + + + + + + + + + + + + + + + */

/* Links */

/* Typography */
.h1 {
    @apply text-4xl font-extrabold tracking-tighter !important;
}

.h2 {
    @apply text-3xl font-extrabold tracking-tighter !important;
}

.h3 {
    @apply text-3xl font-extrabold !important;
}

.h4 {
    @apply text-2xl font-extrabold tracking-tight !important;
}

.ant-typography {
    @apply text-alpes_green-700 text-sm font-extrabold !important;
}

@screen md {
    .h1 {
        @apply text-5xl !important;
    }

    .h2 {
        @apply text-4xl !important;
    }
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}
.no-scrollbar {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}

/* + + + + + + + + + + + + + + + + + + + + + */
/*OVERRIDE ANTDESIGN COMPONENTS*/
/* + + + + + + + + + + + + + + + + + + + + + */

/* Inputs */
input::placeholder {
    @apply text-slate-400 !important;
}
.ant-input {
    @apply text-slate-700 border-slate-300 hover:border-slate-500 focus:border-slate-500 focus:shadow-input focus:shadow-slate-500 !important;
}
.ant-input::selection {
    @apply bg-slate-700 !important;
}
.ant-input-password {
    @apply border-slate-300 hover:border-slate-500 !important;
}
.ant-input-number {
    @apply border-slate-300 hover:border-slate-500 !important;
}
.ant-input-affix-wrapper-focused {
    @apply border-slate-500 shadow-input shadow-slate-500 !important;
}
.ant-input-password .anticon {
    @apply text-slate-400 hover:text-slate-600 !important;
}
/*Input search*/
.ant-input-affix-wrapper {
    @apply border-slate-300 hover:border-slate-500 !important;
}
.ant-input-affix-wrapper-focused {
    @apply border-slate-500 shadow-input shadow-slate-500 !important;
}
.ant-input-search-button {
    @apply border-slate-300 hover:border-slate-500 focus:border-slate-500 !important;
}
/*Input icon*/
.anticon {
    @apply text-slate-400 !important;
}

/* Buttons */
.ant-btn-primary {
    @apply bg-alpes_orange-500 font-semibold border-transparent hover:bg-alpes_orange-400 !important;
}
.ant-btn-primary.second {
    @apply bg-alpes_green-500 font-semibold border-transparent hover:bg-alpes_green-500 !important;
}
.ant-btn-link {
    @apply text-slate-700 p-0 m-0 !important;
}
.ant-btn-default {
    @apply text-alpes_green-700 hover:border-alpes_green-800 !important;
}
.ant-btn-default.remove {
    @apply text-red-700 border-red-700 hover:text-red-800 !important;
}
.ant-btn-link {
    @apply text-alpes_green-700 border-transparent text-sm font-extrabold !important;
}

/*Date picker*/
.ant-picker {
    @apply text-slate-700 border-slate-300 hover:border-slate-500 focus:border-slate-500 focus:shadow-input focus:shadow-slate-500 !important;
}
.ant-picker-focused {
    @apply border-slate-500 shadow-input shadow-slate-500 !important;
}
.ant-picker-active-bar {
    @apply bg-slate-700 !important;
}

/* Dropdowns */
.ant-dropdown-trigger,
.ant-dropdown-menu-item {
    @apply text-slate-700 !important;
}
.ant-dropdown-menu-item:hover {
    @apply bg-gray-50 !important;
}

/* Forms */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none !important;
}

/* Selects */
.ant-select {
    @apply text-slate-700 !important;
}
.ant-select-selector {
    @apply border-slate-300 bg-white hover:border-slate-500 !important;
}

/* Switch */
.ant-switch {
    @apply bg-alpes_green-700 !important;
}

/* Spin */
.ant-spin .ant-spin-dot-item {
    background-color: #5e9400;
}
.ant-spin-text {
    @apply text-alpes_green-700 !important;
}

/* Ta g*/

.ant-tag .ant-tag-blue {
    background-color: #5e9400;
}
