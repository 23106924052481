@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@500;600;700&family=Oswald:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;

/* Theme styles */
@import 'theme/theme.css';

@tailwind utilities;
